import './App.css';
import '../src/styles/image-zoom.css';
import '../src/styles/main.css';
import '../src/styles/style.css';
import AppRoutes from "./routes/AppRoutes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap JavaScript for dropdowns, modals, etc.
import Loader from './components/loader/Loader';

function App() {
  return (
    <>
      <Loader />
      <div className="App">
        <AppRoutes />
      </div>
    </>
  );
}

export default App;
