import React from 'react';
import Questionnaire from '../../features/symptom/Questionnaire';
import { useSelector } from 'react-redux';

const SymptomPage = () => {
    const { muscle_code, enquiry_id } = useSelector(state => state.GlobalVariables);
    return (
        <>
            {/* Questionnaire */}
            <Questionnaire muscle_code={muscle_code} enquiry_id={enquiry_id}/>
        </>
    );
}

export default SymptomPage;
