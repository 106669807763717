import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767, // Set 'sm' to start at 760px
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
