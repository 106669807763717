import React, { memo } from 'react';
import hexa from '../../assests/images/hexa.png';
import hexaBack from '../../assests/images/hexa-back.png';
import { useNavigate } from 'react-router-dom';

const Services = () => {
    //#region 
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/anatomy');
    }
    
    //#endregion

    return (
        <>
            <section className="services">
                <div className="container">
                    <div className="hexa">
                        <ul>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">                                        
                                        <h3>Anatomy Option1</h3>
                                    </div>
                                    <div className="flip-card-back">
                                        <h3>Anatomy Option2</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option2</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option2</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option3</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option3</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option4</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option4</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option5</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option5</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option6</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option6</h3>
                                    </div>
                                </div>
                            </li>
                            <li onClick={handleNavigate}>
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                    <h3>Anatomy Option7</h3>
                                    </div>
                                    <div className="flip-card-back">
                                    <h3>Anatomy Option7</h3>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default memo(Services);
