import { combineReducers } from '@reduxjs/toolkit';
import GlobalVariablesReducer from './reducers/GlobalVariables';
import loaderReducer from './reducers/loaderSlice';

// Combine reducers into a root reducer
const rootReducer = combineReducers({
  GlobalVariables: GlobalVariablesReducer,
  loader: loaderReducer,
  // Add other reducers here
});

export default rootReducer;
