import { Box, TextField, Typography } from '@mui/material'
import React from 'react'

const TextFieldWithTitle = ({ label, name, type, value, handleChange, error = false }) => {
    return (
        <>
            <Box flexDirection={'column'} display={'flex'}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" p={0} m={0} color='#000'>
                        {label}
                    </Typography>
                    <Typography variant="subtitle1" color="error">
                        {error ?? ''}
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    size='small'
                    type={type}
                    placeholder={label}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    error={!!error}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        border: '#CCCCCC',
                        borderRadius: '4px',
                        color: '#333333',
                    }}
                />
            </Box>
        </>
    )
}

export default TextFieldWithTitle
