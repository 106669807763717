import React from 'react';
import { Route, Routes } from "react-router-dom";
import HomePage from '../pages/home/HomePage';
import NotFoundPage from '../pages/error/NotFoundPage';
import AnatomyPage from '../pages/anatomy/AnatomyPage';
import SymptomPage from '../pages/symptom/SymptomPage';
import Layout from '../layouts/Layout';

const AppRoutes = () => {
  return (
    <>
      <Routes>

        <Route path='*' element={<NotFoundPage />} />

        <Route element={<Layout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/anatomy' element={<AnatomyPage />} />
          <Route path='/symptoms' element={<SymptomPage />} />
        </Route>

      </Routes>
    </>
  )
}

export default AppRoutes
