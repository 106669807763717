import React, { memo } from 'react';

const Tagline = () => {
    return (
        <>
            <section className="tagline">
                <div className="container">
                    <h1 className="playfair-display">Your Personal Digital Physio
                        Always with you</h1>
                </div>
            </section>
        </>
    )
}

export default memo(Tagline);
