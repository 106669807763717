import React, { memo } from 'react'

const AnatomyOptions = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-4 text-center"><h4 className="active">Zoom & Mark<span></span></h4></div>
                <div className="col-md-3 col-4"><h4>Symptoms<span></span></h4></div>
                <div className="col-md-3 col-4 text-center"><h4>Result</h4></div>
            </div>
        </>
    )
}

export default memo(AnatomyOptions);
