import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: 'GOPT World',
    muscle_code: null,
    enquiry_id: null,
}

export const GlobalVariableSlice = createSlice({
    name: 'GlobalVariables',
    initialState,
    reducers: {
        rSetName: (state, action) => {
            state.name = action.payload;
        },
        rSetMuscleCode: (state, action) => {
            state.muscle_code = action.payload;
        },
        rSetEnquiryId: (state, action) => {
            state.enquiry_id = action.payload;
        },
    }
});

export const { 
    rSetName, rSetMuscleCode, rSetEnquiryId,
 } = GlobalVariableSlice.actions;

 export default GlobalVariableSlice.reducer;
