import React, { memo } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import videoPlaceholder from '../../assests/images/video-placeholder.png';
import { useMediaQuery } from '@mui/material';

const VideoSection = () => {
    // Use media query to check screen size
    const isMobile = useMediaQuery("(max-width: 760px)"); // Adjust breakpoint as needed

    return (
        <>
            <section className="video-section">
                <div className="container-fluid">
                    <Carousel
                        autoPlay
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        interval={2000}
                        transitionTime={1000}
                        infiniteLoop
                        centerMode
                        centerSlidePercentage={isMobile ? 100 : 25} // Full slide on mobile, 4 slides on desktop
                        stopOnHover={true}
                        axis={"horizontal"} // Vertical for mobile, horizontal for desktop
                        dynamicHeight={true}
                        showArrows={true}
                    >
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Hk5-7RFSsR0?si=t-1iSF3SMTVi6Ya5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/0gEgOKTdOa0?si=TVsDnQnx5s2J_9dk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/Ib8jlwkLYnQ?si=tebrsMcAHladj1xn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/TCyPDywoFto?si=JxvbmqzHwxuEocVV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/EjoztNmAcRI?si=YdDKTi9RJo1Wm_96" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/wA6o0O00nps?si=aod-xv4TTJgri_PE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="video-placeholder">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/M8RyFNN1ZRw?si=Mjv_q5SMHUVCMdPa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </Carousel>
                </div>
            </section>
        </>
    )
}

export default memo(VideoSection);
