import http from "./http";

//#region API endpoints

const login = (data) => {
    return http.post('/login', data)
}
const getActiveQuestionnairesByItemId = (data) => {
    return http.post('/api/get-active-questionnaires-by-itemid', data);
}
const getActiveQuestionnairesByItemCode = (data) => {
    return http.post('/api/get-active-questionnaires-by-itemcode', data);
}
const createQuestionnaireResponse = (data) => {
    return http.post('/api/create-questionnaire-response', data);
}
const createEnquiry = (data) => {
    return http.post('/api/create-enquiry', data);
}

const config = {
    login,
    getActiveQuestionnairesByItemId,
    getActiveQuestionnairesByItemCode,
    createQuestionnaireResponse,
    createEnquiry,
}

export default config;
//#endregion