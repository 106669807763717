import React, { useState, useEffect, memo } from 'react';
import labelImg from '../../assests/images/label.png';
import gem0 from '../../assests/images/gem0.gif';
import gem1 from '../../assests/images/gem1.gif';
import gem2 from '../../assests/images/gem2.gif';
import gem3 from '../../assests/images/gem3.gif';
import gem4 from '../../assests/images/gem4.gif';
import gem5 from '../../assests/images/gem5.gif';
import gem6 from '../../assests/images/gem6.gif';
import gem7 from '../../assests/images/gem7.gif';
import gem8 from '../../assests/images/gem8.gif';
import AnatomyOptions from './AnatomyOptions';
import AnatomyForm from './AnatomyForm';
import { rSetEnquiryId, rSetMuscleCode } from '../../redux/reducers/GlobalVariables';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import config from '../../services/config';
import { useNavigate } from 'react-router-dom';
import { hideLoader, showLoader } from '../../redux/reducers/loaderSlice';

// Store images in an array
const gemImages = [
    gem0,
    gem1,
    gem2,
    gem3,
    gem4,
    gem5,
    gem6,
    gem7,
    gem8
];

const Anatomy1 = () => {
    //#region code
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { muscle_code } = useSelector(state => state.GlobalVariables);
    // const [zoomed, setZoomed] = useState(false);
    // const [index, setIndex] = useState(0);
    const [imageIndex, setImageIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [imageName, setImageName] = useState();
    // const [transformOrigin, setTransformOrigin] = useState("center center");

    // For image zoom
    // const handleZoomClick = (e) => {
    //     setZoomed(!zoomed);
    //     const scale = zoomed ? 1 : 2;
    //     setActiveSection('');
    //     const zoomImage = document.querySelector('.zoom-image');
    //     zoomImage.style.transform = `scale(${scale})`;
    // };

    // For switching between neck, back, knee clicks
    const [activeSection, setActiveSection] = useState('');

    // Example useEffect to change the index dynamically (cycling through images)
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setIndex((prevIndex) => (prevIndex < gemImages.length - 1 ? prevIndex + 1 : 0)); // Loop through images
    //     }, 2000); // Change every 2 seconds

    //     return () => clearInterval(interval); // Cleanup interval on unmount
    // }, []);

    const handleActiveSection = (params) => {
        try {
            setActiveSection(params);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleOpen = (params) => {
        try {
            dispatch(rSetMuscleCode(params));
            setOpen(true);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleNext = () => {
        try {
            setImageIndex((prev) => (prev + 1) % gemImages.length);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handlePrevious = () => {
        try {
            setImageIndex((prev) => (prev - 1 + gemImages.length) % gemImages.length);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        dispatch(rSetMuscleCode(null));
        dispatch(rSetEnquiryId(null));
    },[]);

    useEffect(() => {
        const result = 'gem' + [imageIndex];
        setImageName(result);
        handleActiveSection('');
    }, [imageIndex]);

    // console.log('imageName', imageName);

    // const handleClick = (event) => {
    //     const { offsetX, offsetY, target } = event.nativeEvent;
    //     const { width, height } = target;

    //     // Calculate percentages for transform-origin
    //     const xPercent = (offsetX / width) * 100;
    //     const yPercent = (offsetY / height) * 100;

    //     // Update the transform-origin
    //     setTransformOrigin(`${xPercent}% ${yPercent}%`);
    // };

    const handleSubmit = async(params) => {
      try{
        dispatch(showLoader());
        const res = await config.createEnquiry(params);
            if (res.status === 200) {
                const enquiry_id = res.enquiry_id;
                dispatch(rSetEnquiryId(enquiry_id));
            }
            navigate('/symptoms');
      }
      catch(e){
        console.log(e);
      }
      finally {
          dispatch(hideLoader());
      }
    }
    
    //#endregion

    return (
        <>
            <section className="options-header">
                <div className="container">
                    <AnatomyOptions />
                    <div className="threesixty-wrapper">
                        <div
                            className="threesixty zoom-container"
                            id="threesixty"
                        // onClick={handleZoomClick}
                        >
                            <img
                                src={gemImages[imageIndex]}
                                alt="360 view"
                                className="zoom-image"
                            // style={{
                            //     transformOrigin: transformOrigin,
                            //     transition: "transform 0.3s ease",
                            //     transform: "scale(1.5)", // Adjust scale for zoom effect
                            // }}
                            // onClick={handleClick}
                            />
                            <div className="ui-spinner">
                                <span className="side side-left">
                                    <span className="fill"></span>
                                </span>
                                <span className="side side-right">
                                    <span className="fill"></span>
                                </span>
                            </div>
                        </div>

                        {/* Neck click area */}
                        {
                            (imageName === 'gem4') &&
                            <div className={activeSection === 'neck' ? 'neckclick fullclick neckimg1' : 'neckclick'} onClick={() => handleActiveSection('neck')}>
                                <div className={activeSection === 'neck' ? 'neckai1 show' : 'neckai1'} onClick={() => handleOpen('sple13')}>Neck option 1</div>
                                <div className={activeSection === 'neck' ? 'neckai2 show' : 'neckai2'} onClick={() => handleOpen('trap12')}>Neck option 2</div>
                                <div className={activeSection === 'neck' ? 'neckai3 show' : 'neckai3'} onClick={() => handleOpen('anat11')}>Neck option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem5') &&
                            <div className={activeSection === 'neck' ? 'neckclick fullclick neckimg2' : 'neckclick'} onClick={() => handleActiveSection('neck')}>
                                <div className={activeSection === 'neck' ? 'neckai1 show' : 'neckai1'} onClick={() => handleOpen('sple13')}>Neck option 1</div>
                                <div className={activeSection === 'neck' ? 'neckai2 show' : 'neckai2'} onClick={() => handleOpen('trap12')}>Neck option 2</div>
                                <div className={activeSection === 'neck' ? 'neckai3 show' : 'neckai3'} onClick={() => handleOpen('anat11')}>Neck option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem6') &&
                            <div className={activeSection === 'neck' ? 'neckclick fullclick neckimg3' : 'neckclick'} onClick={() => handleActiveSection('neck')}>
                                <div className={activeSection === 'neck' ? 'neckai1 show' : 'neckai1'} onClick={() => handleOpen('sple13')}>Neck option 1</div>
                                <div className={activeSection === 'neck' ? 'neckai2 show' : 'neckai2'} onClick={() => handleOpen('trap12')}>Neck option 2</div>
                                <div className={activeSection === 'neck' ? 'neckai3 show' : 'neckai3'} onClick={() => handleOpen('anat11')}>Neck option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem7') &&
                            <div className={activeSection === 'neck' ? 'neckclick fullclick neckimg4' : 'neckclick'} onClick={() => handleActiveSection('neck')}>
                                <div className={activeSection === 'neck' ? 'neckai1 show' : 'neckai1'} onClick={() => handleOpen('sple13')}>Neck option 1</div>
                                <div className={activeSection === 'neck' ? 'neckai2 show' : 'neckai2'} onClick={() => handleOpen('trap12')}>Neck option 2</div>
                                <div className={activeSection === 'neck' ? 'neckai3 show' : 'neckai3'} onClick={() => handleOpen('anat11')}>Neck option 3</div>
                            </div>
                        }

                        {/* Back click area */}
                        {
                            (imageName === 'gem4') &&
                            <div className={activeSection === 'back' ? 'backclick fullclick backimg1' : 'backclick'} onClick={() => handleActiveSection('back')}>
                                <div className={activeSection === 'back' ? 'backai1 show' : 'backai1'} onClick={() => handleOpen('rhom16')}>Back option 1</div>
                                <div className={activeSection === 'back' ? 'backai2 show' : 'backai2'} onClick={() => handleOpen('lati15')}>Back option 2</div>
                                <div className={activeSection === 'back' ? 'backai3 show' : 'backai3'} onClick={() => handleOpen('erec14')}>Back option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem5') &&
                            <div className={activeSection === 'back' ? 'backclick fullclick backimg2' : 'backclick'} onClick={() => handleActiveSection('back')}>
                                <div className={activeSection === 'back' ? 'backai1 show' : 'backai1'} onClick={() => handleOpen('rhom16')}>Back option 1</div>
                                <div className={activeSection === 'back' ? 'backai2 show' : 'backai2'} onClick={() => handleOpen('lati15')}>Back option 2</div>
                                <div className={activeSection === 'back' ? 'backai3 show' : 'backai3'} onClick={() => handleOpen('erec14')}>Back option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem6') &&
                            <div className={activeSection === 'back' ? 'backclick fullclick backimg3' : 'backclick'} onClick={() => handleActiveSection('back')}>
                                <div className={activeSection === 'back' ? 'backai1 show' : 'backai1'} onClick={() => handleOpen('rhom16')}>Back option 1</div>
                                <div className={activeSection === 'back' ? 'backai2 show' : 'backai2'} onClick={() => handleOpen('lati15')}>Back option 2</div>
                                <div className={activeSection === 'back' ? 'backai3 show' : 'backai3'} onClick={() => handleOpen('erec14')}>Back option 3</div>
                            </div>
                        }

                        {/* Knee click area */}
                        {
                            (imageName === 'gem0') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick kneeimg1' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem1') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg2' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem2') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg3' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem3') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg4' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem4') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg5' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem5') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg6' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem6') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg7' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem7') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg8' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }
                        {
                            (imageName === 'gem8') &&
                            <div className={activeSection === 'knee' ? 'kneeclick fullclick  kneeimg9' : 'kneeclick'} onClick={() => handleActiveSection('knee')}>
                                <div className={activeSection === 'knee' ? 'kneeai1 show' : 'kneeai1'} onClick={() => handleOpen('hams18')}>Knee option 1</div>
                                <div className={activeSection === 'knee' ? 'kneeai2 show' : 'kneeai2'} onClick={() => handleOpen('quad17')}>Knee option 2</div>
                                <div className={activeSection === 'knee' ? 'kneeai3 show' : 'kneeai3'} onClick={() => handleOpen('quad19')}>Knee option 3</div>
                            </div>
                        }

                    </div>
                    <div className="container threesixty-wrapper" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div className="ui">
                            <Box className="next" onClick={handlePrevious} sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <ArrowForwardIos />
                            </Box>
                            <Box className="prev" onClick={handleNext} sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <ArrowBackIos />
                            </Box>
                            <img src={labelImg} alt="" className="label" />
                        </div>
                    </div>
                </div>
            </section>
            <AnatomyForm open={open} handleClose={handleClose} onSubmit={handleSubmit}/>
        </>
    );
}

export default memo(Anatomy1);
