import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer  } from 'redux-persist';
import persistConfig from './PersistConfig';
import rootReducer from './RootReducer';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(Store);

export default Store;
