import React, { memo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    Grid,
    IconButton,
    Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextFieldWithTitle from '../../components/textfields/TextFieldWithTitle';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import config from '../../services/config';
import { rSetEnquiryId } from '../../redux/reducers/GlobalVariables';
import { useDispatch } from 'react-redux';
import { DisabledByDefault, Save, SkipNext } from '@mui/icons-material';


// FUNCTION
const AnatomyForm = ({ open, handleClose, onSubmit, skip = true }) => {
    //#region code
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialFormData = {
        name: '',
        email: '',
        phone: '',
        age: '',
        gender: 'Male',
        height: '',
        weight: '',
        status: true,
        is_customer: false,
    }
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});

    const handleChange = (e) => {
        try {
            const { name, value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));

            if (validationErrors[name]) {
                setValidationErrors((prev) => ({
                    ...prev,
                    [name]: ''
                }))
            }
        }
        catch (e) {
            console.log(e);
        }
    };

    const validateForm = async () => {
        try {
            const errors = {};
            if (validator.isEmpty(formData.name)) {
                errors.name = "Required";
            }
            if (validator.isEmpty(formData.email)) {
                errors.email = "Required";
            }
            else if (!validator.isEmail(formData.email)) {
                errors.email = "Invalid format";
            }
            if (validator.isEmpty(formData.age)) {
                errors.age = "Required";
            }
            else if (formData.age <= 0) {
                errors.age = "Invalid age";
            }
            if (validator.isEmpty(formData.gender)) {
                errors.gender = "Required";
            }
            setValidationErrors(errors);
            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const isValid = await validateForm();
            if (isValid === false) {
                return false;
            }
            const dataToSend = {
                ...formData
            }
            onSubmit(dataToSend);
            handleReset();
        }
        catch (e) {
            console.log(e);
        }
        finally {

        }
    };

    const handleReset = () => {
        try {
            setFormData(initialFormData);
            setValidationErrors({});
            handleClose();
        }
        catch (e) {
            console.log(e);
        }
    }

    //#endregion

    return (
        <Dialog open={open} onClose={handleReset} fullWidth maxWidth="sm">
            <DialogTitle sx={{ padding: 0 }}>
                <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
                    <IconButton onClick={handleReset} sx={{ cursor: 'pointer', fontSize: '36px' }}>
                        <DisabledByDefault color='error' fontSize='36px' />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container alignItems={'center'} spacing={1}>
                        <Grid item xs={12}>
                            <TextFieldWithTitle label={'Name'} name={'name'} type={'text'} value={formData.name || ""} handleChange={handleChange} error={validationErrors.name || null} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldWithTitle label={'Email'} name={'email'} type={'text'} value={formData.email || ""} handleChange={handleChange} error={validationErrors.email || null} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldWithTitle label={'Phone'} name={'phone'} type={'number'} value={formData.phone || ""} handleChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldWithTitle label={'Age'} name={'age'} type={'number'} value={formData.age || ""} handleChange={handleChange} error={validationErrors.age || null} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box flexDirection={'column'} display={'flex'}>
                                <Typography variant="body1" p={0} m={0}>
                                    Gender
                                </Typography>
                                <FormControl fullWidth size='small'>
                                    {/* <InputLabel>Gender</InputLabel> */}
                                    <Select
                                        fullWidth
                                        size={'small'}
                                        name="gender"
                                        value={formData.gender}
                                        onChange={handleChange}
                                        error={validationErrors.gender || null}
                                        sx={{ backgroundColor: 'white' }}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Transgender">Transgender</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldWithTitle label={'Height(cm)'} name={'height'} type={'number'} value={formData.height || ""} handleChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldWithTitle label={'Weight(kg)'} name={'weight'} type={'number'} value={formData.weight || ""} handleChange={handleChange} />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={6}>
                        <Button fullWidth type="submit" onClick={handleSubmit} variant="contained" color='primary' startIcon={<Save />}>
                            {skip ? "Submit" : "Save"}
                        </Button>
                    </Grid>
                    {
                        skip &&
                        <Grid item xs={6}>
                            <Button fullWidth variant='contained' color='secondary' onClick={() => navigate('/symptoms')} startIcon={<SkipNext />}>
                                Skip
                            </Button>
                        </Grid>
                    }
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default memo(AnatomyForm);
