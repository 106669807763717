import React, { memo, useState } from 'react';
import logoGopt from '../../assests/images/logo-gopt.png';
import { useNavigate } from 'react-router-dom';

//FUNCTION
const NavBar = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <nav className="navbar navbar-expand-sm">
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={logoGopt} alt="" className="img-fluid" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav poppins-regular">
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => navigate('/')}>Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    href="#"
                                    className="nav-link dropdown-toggle"
                                    role="button"
                                    onClick={toggleNavbar}
                                >
                                    Services
                                </a>
                                {isOpen && (
                                    <ul className="dropdown-menu show">
                                        <li><a className="dropdown-item" onClick={() => {
                                            toggleNavbar();
                                            navigate('/anatomy');
                                        }}>Anatomy</a></li>
                                        {/* <li><a className="dropdown-item" href="#" onClick={() => {
                                            toggleNavbar();
                                            navigate('/symptoms');
                                        }}>Symptoms</a></li>
                                        <li><a className="dropdown-item" href="#" onClick={toggleNavbar}>A third link</a></li> */}
                                    </ul>
                                )}
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Blog</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    <div className="res-appointment-btn"><button className="poppins-regular">Book An Appointment</button></div>
                </div>
            </nav>
        </>
    )
}

export default memo(NavBar);
