import storage from 'redux-persist/lib/storage';
import { encryptTransformConfig } from './Encription';

const PersistConfig = {
  key: 'gopt',
  storage,
  transforms: [encryptTransformConfig],
  whitelist: ['GlobalVariables'],
  // Other configuration options can be added here
};

export default PersistConfig;
