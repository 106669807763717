import React, { memo } from 'react';
import phoneCallIcon from '../../assests/images/phone-call-1.png';
import emailIcon from '../../assests/images/email-icon.png';

//Function
const TopBar = () => {
    return (
        <>
            <section className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 poppins-regular">
                            <ul className="text-right">
                                <li>
                                    <a href="tel:+919716699992">
                                        <img src={phoneCallIcon} alt="" /> 
                                        Call us 9716699992
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@goptindia.com">
                                        <img src={emailIcon} alt="" /> 
                                        info@goptindia.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default memo(TopBar);
