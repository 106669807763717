import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '../components/topbars/TopBar';
import NavBar from '../components/navbars/NavBar';

const Layout = () => {
    return (
        <>
            {/* Top Bar */}
            <TopBar />

            {/* Navbar */}
            <NavBar />

            <Outlet />
        </>
    )
}

export default Layout
