import React, { memo, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import info1 from '../../assests/images/info1.png';
import info2 from '../../assests/images/info2.png';
import SymptomOptionHeader from './SymptomOptions';
import config from '../../services/config';
import SubmitButton from '../../components/buttons/SubmitButton';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AnatomyForm from '../anatomy/AnatomyForm';
import { rSetEnquiryId } from '../../redux/reducers/GlobalVariables';
import { hideLoader, showLoader } from '../../redux/reducers/loaderSlice';


//FUNCTION
const Questionnaire = ({ muscle_code, enquiry_id }) => {
    //#region code
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [questionnaires, setQuesionnaires] = useState([]);
    const [responses, setResponses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3; // Number of questionnaires per page

    const totalPages = Math.ceil(questionnaires.length / itemsPerPage);

    // Get questionnaires for the current page
    const paginatedQuestionnaires = questionnaires.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handlers for navigation buttons
    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        const fetchQuestionnaires = async () => {
            await fetchActiveQuestionnaires();
        }
        fetchQuestionnaires();
    }, []);

    //fetch active questionnaires
    const fetchActiveQuestionnaires = async () => {
        try {
            dispatch(showLoader());
            setLoading(true);
            const dataToSend = {
                item_code: muscle_code
            }
            const res = await config.getActiveQuestionnairesByItemCode(dataToSend);
            if (res.status === 200) {
                setQuesionnaires(res.data);
            }
            else {
                setQuesionnaires([]);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setLoading(false);
            dispatch(hideLoader());
        }
    }

    // Function to handle radio button change
    const handleRadioChange = (questionnaire, value) => {
        try {
            setResponses((prevResponses) => {
                const existingResponseIndex = prevResponses.findIndex(
                    (response) => response.questionnaire_id === questionnaire.id
                );

                if (
                    existingResponseIndex !== -1 &&
                    prevResponses[existingResponseIndex].response_data === value
                ) {
                    // Remove the response if the same value is clicked again
                    return prevResponses.filter(
                        (response) => response.questionnaire_id !== questionnaire.id
                    );
                } else {
                    // Add new response or update existing one
                    const updatedResponses = [...prevResponses];

                    if (existingResponseIndex !== -1) {
                        // Update existing response
                        updatedResponses[existingResponseIndex].response_data = value;
                    } else {
                        // Add new response
                        updatedResponses.push({
                            questionnaire_id: questionnaire.id,
                            item_id: questionnaire.item_id,
                            user_id: enquiry_id,
                            response_data: value,
                        });
                    }

                    return updatedResponses;
                }
            });
        }
        catch (e) {
            console.log(e);
        }
    };

    // Function to save the selected values
    const handleSubmit = async (userAlreadyRegistered = true, id = null) => {
        try {
            if (responses.length === 0) {
                Swal.fire({
                    'icon': 'warning',
                    'title': 'Please select an option before continuing. '
                });
                return false;
            }
            dispatch(showLoader());
            if (userAlreadyRegistered === false) {
                // Update all user_id fields
                responses.forEach(item => {
                    item.user_id = id;
                });
            }
            const dataToSend = {
                questionnaire_response: responses,
                user_id: userAlreadyRegistered ? enquiry_id : id,
            }
            const res = await config.createQuestionnaireResponse(dataToSend);
            if (res.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Thank You',
                    text: 'Thank you for sharing your information with us. Please keep an eye on your inbox, as we will reach out to you via the email address you provided.'
                });
                navigate('/anatomy');
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Sorry',
                    text: 'We’re sorry, but it appears there was an issue saving your information. Please try submitting your information again.'
                });
            }
        }
        catch (e) {
            console.log(e);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to save response.'
            });
        }
        finally {
            dispatch(hideLoader());
        }
    };

    const handleOpen = () => {
        if (responses.length > 0) {
            setOpen(true);
        }
        else {
            Swal.fire({
                'icon': 'warning',
                'text': 'Please select an option before continuing. '
            });
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleEnquiry = async (params) => {
        try {
            dispatch(showLoader());
            const res = await config.createEnquiry(params);
            if (res.status === 200) {
                const enquiry_id = res.enquiry_id;
                dispatch(rSetEnquiryId(enquiry_id));
                await handleSubmit(false, enquiry_id);
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            dispatch(hideLoader());
        }
    }


    //#endregion

    return (
        <>
            <Toaster />
            <section className="options-header-1">
                <div className="container">
                    <SymptomOptionHeader />
                    <section className="questionaries">
                        <div className="container">
                            <div className="text-center col-md-6 offset-md-3">
                                <h3 className="text-center">Crosscheck Symptoms</h3>
                                <h4 className="text-center">
                                    <img src={info1} alt="info" /> How often do you get these symptoms?
                                </h4>

                                {loading ? (
                                    <Box
                                        sx={{
                                            display: 'flex !important',
                                            justifyContent: 'center !important',
                                            alignItems: 'center !important',
                                            height: '20vh !important',
                                            backgroundColor: 'transparent !important',
                                            position: 'center !important',
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : paginatedQuestionnaires.length > 0 ? (
                                    <>
                                        {paginatedQuestionnaires.map((questionnaire, index) => (
                                            <div className="mb-3 mt-3" key={index}>
                                                <h6 className="text-center">
                                                    <div className="centeroption">{questionnaire.title}</div>
                                                    <Tooltip
                                                        title={
                                                            <Typography variant="body2" color="inherit">
                                                                {questionnaire.description || ""}
                                                            </Typography>
                                                        }
                                                        arrow
                                                        placement="top"
                                                        sx={{
                                                            "& .MuiTooltip-tooltip": {
                                                                backgroundColor: "#333",
                                                                color: "white",
                                                                fontSize: "0.875rem",
                                                                padding: "8px 12px",
                                                                borderRadius: "4px",
                                                            },
                                                            "& .MuiTooltip-arrow": {
                                                                color: "#333",
                                                            },
                                                        }}
                                                    >
                                                        <img src={info2} alt="info" />
                                                    </Tooltip>
                                                </h6>
                                                <div className="set">
                                                    {[...Array(10).keys()].map((num) => (
                                                        <Tooltip
                                                            key={num}
                                                            title={
                                                                <Typography variant="body2" color="inherit">
                                                                    {`${(num + 1) * 10}%`}
                                                                </Typography>
                                                            }
                                                            arrow
                                                            placement="top"
                                                            aria-placeholder={<Typography variant="body2" color="inherit">
                                                                {`${(num + 1) * 10}%`}
                                                            </Typography>}
                                                            sx={{
                                                                "& .MuiTooltip-tooltip": {
                                                                    backgroundColor: "#333",
                                                                    color: "white",
                                                                    fontSize: "0.875rem",
                                                                    padding: "8px 12px",
                                                                    borderRadius: "4px",
                                                                },
                                                                "& .MuiTooltip-arrow": {
                                                                    color: "#333",
                                                                },
                                                            }}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="form-control"
                                                                value={num + 1}
                                                                name={`symptom_${questionnaire.id}`}
                                                                checked={
                                                                    responses.find(
                                                                        (response) => response.questionnaire_id === questionnaire.id
                                                                    )?.response_data === num + 1
                                                                }
                                                                onClick={() => handleRadioChange(questionnaire, num + 1)}
                                                                style={{
                                                                    border:
                                                                        responses.find(
                                                                            (response) => response.questionnaire_id === questionnaire.id
                                                                        )?.response_data === num + 1
                                                                            ? "4px dashed grey"
                                                                            : "none",
                                                                    padding: "5px",
                                                                    margin: "3px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div className="mb-3 mt-3">
                                        <div className="text-center">
                                            <Typography variant="h6" color="error" fontWeight={700}>
                                                {muscle_code === null ? "Please select the pain area." : "No Questionnaires."}
                                            </Typography>
                                        </div>
                                    </div>
                                )}

                                {/* Navigation Buttons */}
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    {/* Previous Button - Positioned on the Left */}
                                    <input
                                        type='submit'
                                        disabled={currentPage === 1}
                                        onClick={goToPreviousPage}
                                        value="Previous"
                                        className="btn btn-primary"
                                    />

                                    {/* Next/Save Button - Positioned on the Right */}
                                    {currentPage === totalPages ? (
                                        <>
                                            {
                                                enquiry_id === null ?
                                                    <input
                                                        type='submit'
                                                        onClick={handleOpen}
                                                        value="Next"
                                                        className="btn btn-primary"
                                                    />
                                                    :
                                                    <input
                                                        type='submit'
                                                        onClick={handleSubmit}
                                                        value="Save"
                                                        className="btn btn-primary"
                                                    />
                                            }
                                        </>
                                    ) : (
                                        <input
                                            type='submit'
                                            onClick={goToNextPage}
                                            value="Next"
                                            className="btn btn-primary"
                                        />
                                    )}
                                </div>


                            </div>
                        </div>
                    </section>
                </div >
            </section >
            <AnatomyForm open={open} handleClose={handleClose} onSubmit={handleEnquiry} skip={false} />
        </>
    )
}

export default memo(Questionnaire);
