import React, { memo } from 'react';
import Tagline from './Tagline';
import Services from './Services';
import VideoSection from './VideoSection';

const Home = () => {
  return (
    <>
      <Tagline />
      <Services />
      <VideoSection />
    </>
  )
}

export default memo(Home);
