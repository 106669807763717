import React from 'react';
import Anatomy from '../../features/anatomy/Anatomy';
import Anatomy1 from '../../features/anatomy/Anatomy1';
import { ThemeProvider } from '@mui/material';
import theme from '../../themes/theme';

const AnatomyPage = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                {/* Anatomy */}
                <Anatomy1 />
            </ThemeProvider>
        </>
    );
};

export default AnatomyPage;
